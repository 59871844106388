import axios from 'axios';
import appConfig from '../../config/constant';

const API = axios.create({
    baseURL: appConfig.BASE_URL
});


API.interceptors.request.use(function (config) {
    // console.log(config, 'in axios view Data');
    // if ((config.method === 'put' || config.method === 'post') && config.url?.includes('game')) {
    //     // console.log("multipart/form-data Calling:SSS", config.headers['Content-Type']);
    //     if(!config.headers['Content-Type']) {
    //         config.headers['Content-Type'] = 'multipart/form-data';
    //     }
    //     return config;
    // }
    if(!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
});

API.interceptors.response.use(
    (response) => {
        // console.log("Res:", response);
        if (response.data) {
            return response.data;
        }
        else {
            return response.data ? response.data : {};
        }
    }, (error) => {
        console.log(error);
    }
);


export default API;