import React, { useEffect, useState } from 'react';
import FormBuilder from '../components/form-builder';
import { FormValidator, GetControlIsValid } from '../components/form-builder/validations';
import { FormControlError, FormField, FormValidators } from '../components/form-builder/model/form-field';
import { LOGIN } from '../app/service/login.service';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import logo from '../assets/images/icons/logo.png';
import { validateName, nameValidations, emailCompanyValidations, emialValidations } from '../components/form-builder/form-validations';


const SinUp = () => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [parentsData, setParentsData] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [currentParentsData, setCurrentParentsData] = useState<any>({});
    const [resValidationError, setResValidationError] = useState('');
    const navigation = useNavigate();

    const handleInput = (data: any) => {
        setParentsData(data);
    };
    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }
    const handleGender = (e: any) => {
        const data = { ...parentsData.value };
        data.gender = e.target.value;
        if (currentParentsData) {
            currentParentsData.gender = e.target.value;
        }
    }
    const formValidations = [
        new FormField('gender', [FormValidators.REQUIRED]),
    ];
    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const data = { ...parentsData.value };
        console.log('form ', parentsData);
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        if (parentsData.isValid) {
            LOGIN(data).then((res: any) => {
                // navigation('/gameList');
                navigation("/")
            }).catch((err) => {
                console.error(err);
                setResValidationError('Already email exist  -- > Pls use another email');
            })
        } else {
            console.log("Error page:", data)
        }
    }
    return (
        <div className="container-fluid">
            <div className="row ">
                <div className="col-sm-12 col-md-4 d-none d-lg-block bg-dark">
                    <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                        <div className=" mt-4 w-50">
                            <img src={logo} alt="" className='w-100' />
                        </div>
                        <div></div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-8 d-none d-inline-block d-lg-block bg-danger ">
                    <div className="text-white">
                        <h2 className='label_field mt-5'> Account Details</h2>
                        <p className='text_font font-weight-bold text-white'>Set your account particulars here. You may change them later.</p>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                        <div className="d-flex w-50 px-5 mx-5 flex-column shadow p-3 mb-5 bg-white rounded-4">
                            <FormBuilder onUpdate={handleInput} showValidations={isFormSubmitted}>
                                <form action="" className=' '>
                                    <div className="mb-4 mt-3">
                                        <label htmlFor="login_username" className='d-flex justify-content-start'>Name
                                            <span className='star_icon'>*</span></label>
                                        <input type="text" name="name" id="login_username" className="form-control d-flex justify-content-start" defaultValue={currentParentsData.name} placeholder="Name" data-validate-required="Please enter name" data-validate-name="name" />
                                    </div>
                                    <div className=" mb-4">
                                        <label className="form-label mb-0 d-flex justify-content-start" >Email ID<span className='star_icon'>*</span>
                                        </label>
                                        <input className="form-control" type="text" name="email" defaultValue={currentParentsData.email} placeholder="Email id" data-validate-required="Please enter your email" data-validate-email="email Id" />
                                    </div>
                                    <div className=" mb-4">
                                        <label className="form-label mb-0 d-flex justify-content-start">Phone Number<span className='star_icon'>*</span></label>
                                        <input className="form-control" type="text" name="phone_number" maxLength={10} defaultValue={currentParentsData.phone_number} placeholder="Phone Number" data-validate-required="Please enter mobile number" data-validate-mobile-number="Phone Number" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label mb-0 d-flex justify-content-start">Password<span className='star_icon'>*</span> </label>
                                        <input className="form-control d-flex justify-content-start" type="password" name="Pwd_Hash" defaultValue={currentParentsData.Pwd_Hash} placeholder="Password" data-validate-required="Please enter password" data-validate-password="password" />
                                    </div>
                                    <div className="mb-4 w-100 ">
                                        <label className="form-label mb-0 d-flex justify-content-start">Gender <span className='star_icon'>*</span></label>
                                        <span className='d-flex justify-content-start'>
                                            <input type="radio" name="gender" value="male" checked={currentParentsData?.gender === "male"} onChange={(e) => { handleGender(e) }} data-validate-required="Please enter gender" />
                                            <span style={{ margin: '0px 12px' }}>Male</span>
                                            <input type="radio" name="gender" value="female" checked={currentParentsData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                            <span style={{ margin: '0px 12px' }}>Female</span>
                                            <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentParentsData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                            <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                                        </span>
                                        {isFormSubmitted && !getInputValid('gender') && <p className="text-danger  d-flex justify-content-start">Please select gender</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label mb-0 d-flex justify-content-start">Address</label>
                                        <input className="form-control" type="text" placeholder="Enter Address" defaultValue={currentParentsData.address} name="address" data-validate-addresh="Address" />
                                    </div>
                                    {/* </div> */}
                                </form>
                            </FormBuilder>
                            {resValidationError && <p className="text-danger">{resValidationError}</p>}
                            <div className="form-footer border-top border-primary  text-center mt-2">
                                <button type="submit" className='btn btn-primary mb-1 w-75 mt-3' onClick={handleSubmit} style={{ fontFamily: "Montserrat", fontWeight: "bold" }}> Sign up </button>
                            </div>
                            <div className='my-2'>
                                <div className=''>Already Have An Account?<span className='fw-bold'><Link to="/"> Sigin</Link></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SinUp;