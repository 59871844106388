import React, { useEffect } from 'react'
import { connect } from 'react-redux';

interface Props {
    user?: any;
}
const Blog = (props: Props) => {
    useEffect(() => {
      console.log('props ', props);
      
    }, []);
    
    return (
        <div className='bg-white' style={{ height: '90vh' }}>
            {props.user && <iframe src={`https://blogs.mrdev.co.in/?client=66964c0822171c2c834c9c6a&mapped_id=${props.user._id}&name=${props.user.name}&profile_pic=${props.user.profile_img||''}&write=true`} width={'100%'} className='h-100'></iframe>}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.PlatformStore.user
});

export default connect(mapStateToProps)(Blog);