import { Socket, io } from "socket.io-client";
import appConfig from "../../config/constant";
import { ReduxStore } from "../redux/store";
import { PlatformActionType } from "../redux/reducer/types";

export class SocketSvc {
    private static socketIO: Socket;

    static initialize() {
        console.log('initialized socket', appConfig.SOCKET_URL);

        SocketSvc.socketIO = io(appConfig.SOCKET_URL as string, { secure: true });

        SocketSvc.socketIO.on('user-connection', SocketSvc.onUsersUpdated);

        SocketSvc.socketIO.on('connect', ()=>{
            // console.log('Admin connected');
            SocketSvc.socketIO.emit('joinRoom', 'admin-console');
            
        });

    }

    static onUsersUpdated(data: any): void {
        ReduxStore.dispatch({
            type: PlatformActionType.USER_COUNT,
            data
        })
    }
}
