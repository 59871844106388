import React, { SyntheticEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ReportsService } from '../app/service/reports.service';
import { GridDataConfig, MRDataGrid } from '../components/data-grid';
import { GameReport } from '../app/model/game-report';
import { Outlet, useNavigate, useNavigation } from 'react-router-dom';
import { Loading } from '../app/components/loading';

interface Props {
    totalUsers?: number;
}
const GameReports = (props: Props) => {
    const navigation = useNavigate();
    const [gameReportData, setGameReportData] = useState<GameReport[]>([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sortKey, setSortKey] = useState('total_plays');
    const [sortDirection, setSortDirection] = useState(-1);
    const tableConfig: GridDataConfig = {
        cols: [
            {
                field: 'name',
                title: 'Game',
                type: 'link-btn'
            },
            {
                field: 'total_plays',
                title: 'Total Plays',
                sortable: true
            },            
            {
                field: 'single_player_games',
                title: 'Sigle Player',
                sortable: true
            },
            {
                field: 'multi_player_games',
                title: 'Multi Player',
                sortable: true
            },
            {
                field: 'bot_player_games',
                title: 'Bot Player',
                sortable: true
            },
            {
                field: 'likes',
                title: 'Likes'
            }
        ],
    };
    let isLoaded = false;
    useEffect(() => {
        if (!isLoaded) {
            loadReports();
        }
        isLoaded = true;
    }, []);

    useEffect(() => {
        loadReports();
    }, [startDate, endDate, sortDirection, sortKey]);

    const loadReports = async () => {
        setIsLoading(true);
        const data = await ReportsService.getGameReports(startDate, endDate, sortKey, sortDirection);
        setGameReportData(data);
        setIsLoading(false);
    }

    const onTableRowClick = (data: any) => {
        navigation(data._id);
    }

    const onChangeStartDate = (data: SyntheticEvent) => {
        const inputEl = data.target as HTMLInputElement;
        setStartDate(inputEl.value);
    }

    const onChangeEndDate = (data: SyntheticEvent) => {
        const inputEl = data.target as HTMLInputElement;
        setEndDate(inputEl.value);
    }

    const onChangeSort = (sortKey: string, direction: number)=>{
        setSortKey(sortKey);
        setSortDirection(direction);
    }

    return (
        <div className="mt-3">
            <div className="d-flex mb-3">
                <div>
                    <label>Start</label>
                    <input onChange={onChangeStartDate} type="date" name="" id="" className='form-control' />
                </div>
                <div className='ms-3'>
                    <label>End</label>
                    <input type="date" onChange={onChangeEndDate} className='form-control' />
                </div>
            </div>
            {isLoading && <Loading></Loading>}
            <MRDataGrid sortDirection={sortDirection} sortKey={sortKey} onRowClick={onTableRowClick} config={tableConfig} data={gameReportData} onChangeSort={onChangeSort}></MRDataGrid>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    totalUsers: state.PlatformStore.totalUsers
});

export default connect(mapStateToProps)(GameReports);
