import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { GridDataConfig, MRDataGrid } from '../../components/data-grid'
import { ReportsService } from '../service/reports.service';
import { useParams } from 'react-router-dom';
import { GameReport } from '../model/game-report';

export const ChallengeReports = () => {
    const [gameReportData, setGameReportData] = useState<GameReport[]>([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const tableConfig: GridDataConfig = {
        cols: [
            {
                field: 'name',
                title: 'User',
            },
            {
                field: 'challenges',
                title: 'Challenges'
            },
            {
                field: 'accepted_challenges',
                title: 'Accepted'
            },
            {
                field: 'min_bet_amount',
                title: 'Min. Bet'
            },
            {
                field: 'max_bet_amount',
                title: 'Max. Bet'
            }
        ]
    };
    let isLoaded = false;
    useEffect(() => {
        if (!isLoaded) {
            isLoaded = true;
            loadIndividualGameReports();
        }
    }, []);

    useEffect(() => {
        loadIndividualGameReports();
    }, [startDate, endDate])


    const loadIndividualGameReports = async () => {
        const data = await ReportsService.getChallengeReports(startDate, endDate);
        setGameReportData(data);
    }

    const onChangeStartDate = (data: SyntheticEvent) => {
        const inputEl = data.target as HTMLInputElement;
        setStartDate(inputEl.value);
    }

    const onChangeEndDate = (data: SyntheticEvent) => {
        const inputEl = data.target as HTMLInputElement;
        setEndDate(inputEl.value);
    }

    return (
        <div className="mt-3 p-3">
            <div className="d-flex mb-3">
                <div>
                    <label>Start</label>
                    <input onChange={onChangeStartDate} type="date" name="" id="" className='form-control' />
                </div>
                <div className='ms-3'>
                    <label>End</label>
                    <input type="date" onChange={onChangeEndDate} className='form-control' />
                </div>
            </div>
            <MRDataGrid config={tableConfig} data={gameReportData}></MRDataGrid>
        </div>
    )
}
