import React, { SyntheticEvent, useEffect, useState } from 'react'
import { GridDataConfig, MRDataGrid } from '../../components/data-grid'
import { GameReport } from '../model/game-report';
import { ReportsService } from '../service/reports.service';
import { UserReportRes } from '../model/user-report.res';

export const UserReports = () => {
    const [gameReportData, setGameReportData] = useState<UserReportRes[]>([]);
    const [searchStr, setSearchStr] = useState('');
    const tableConfig: GridDataConfig = {
        cols: [
            {
                field: 'name',
                title: 'User',
            },
            {
                field: 'coins',
                title: 'Coins',
            },
            {
                field: 'total_game_plays',
                title: 'Games Plays',
            },
            {
                field: 'games',
                title: 'Games',
            }
        ]
    };
    // let isLoaded = false;
    // useEffect(() => {
    //     if (!isLoaded) {
    //         loadUserReports();
    //     }
    //     isLoaded = true;
    // }, []);

    useEffect(() => {
        loadUserReports();
    }, [searchStr])
    

    const loadUserReports = async () => {
        const data = await ReportsService.getUserReports(searchStr);
        setGameReportData(data);
    }

    const onChangeSearch = (event: SyntheticEvent) => {
        const inputEl = event.target as HTMLInputElement;
        setSearchStr(inputEl.value);
    }
    return (
        <div className="mt-3 p-3">
            <div className="d-flex mb-3">
                <div>
                    <input onChange={onChangeSearch} type="text" name="" id="" className='form-control' placeholder='Search by user name' />
                </div>
            </div>
            <MRDataGrid config={tableConfig} data={gameReportData}></MRDataGrid>
        </div>
    )
}
