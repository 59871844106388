import { FormControlError, FormField, FormValidators } from "./model/form-field";

export const FormValidator = (formFields: FormField[], data: any): FormControlError[] => {

    const errors: FormControlError[] = [];
    for (let index = 0; index < formFields.length; index++) {
        const element = formFields[index];
        for (let j = 0; j < element.validators.length; j++) {
            const validatorElement = element.validators[j];
            switch (validatorElement) {
                case FormValidators.REQUIRED:
                    if (!data.hasOwnProperty(element.control)) {
                        errors.push(new FormControlError(element.control, FormValidators.REQUIRED));
                    } else {
                        if (!data[element.control]) {
                            errors.push(new FormControlError(element.control, FormValidators.REQUIRED));
                        }
                    }
                    break;

                default:
                    break;
            }
        }
    }
    return errors;
}
export const GetControlIsValid = (errors: FormControlError[], control: string) => {
    const isInvalid = errors.find(el => el.control === control) ? true : false;
    return !isInvalid;
}