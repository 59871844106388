export interface PlatformState {
    totalUsers: number;
    user: any;
}

export enum PlatformActionType {
    USER_COUNT = 'user_count',
    USER_LOGIN = 'user_login'
}
export interface PlatformStateAction {
    type: PlatformActionType;
    data: any;
}