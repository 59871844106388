import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Search } from '../app/service/search.service';
import { PublishService } from '../app/service/publisher.service';
import LogoImage from './../assets/images/icons/moplay.png';
import { Header } from '../components/header';
import { SideMenu } from '../components/side-menu';
import Pagination from '../components/pagination';
import CustomPagination from '../components/pagination';

export const ManagePublishers = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showPublisherForm, setShowPublisherForm] = useState<boolean>(false);
    const [publisherData, setPublisherData] = useState<any>([]);
    // const [currentPageData, setCurrentPageData] = useState([]);
    const [itemsPerPage, setItemPerPage] = useState<any>(10);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const searchInput = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState('');
    const navigation = useNavigate();

    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        loadPublishers();
    }, [currentPage, searchValue]);

    const changeSearchInput = () => {
        const value = (searchInput.current as HTMLInputElement).value;
        setSearchValue(value);
    }

    const loadPublishers = () => {
        setLoading(true);
        const startVal = (currentPage - 1) * itemsPerPage;
        PublishService.GetPublishers(startVal, itemsPerPage, searchValue).then((res: any) => {
            setPublisherData(res.records);
            setTotalRecords(res.total)
            setLoading(false);
        });
    }

    const onClickAddPublisher = () => {
        setShowPublisherForm(true)
        navigation("../publisher")
    }

    const onClickPublisherNameNavigation = (el: any) => {
        navigation(`../publisher/${el?._id}`)
    }

    const deletePublisher = (el: any) => {
        setLoading(true);
        PublishService.DeletePublisher(el?._id).then((res: any) => {
            console.log("deleted:", res);
            loadPublishers();
            setLoading(false);
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        })
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            changeSearchInput();
        }
    }

    return (
        <>
            <div className='d-flex mt-2'>
                <div className="container-fluid my-2">
                    <div className='row'>
                        <div className='col-md-12 d-flex justify-content-between'>
                            <div className='fs-4 my-2 mb-3 fw-bold text-start'>Publisher</div>
                            <div className='my-2 mb-3'>
                                <button className='btn btn-primary' style={{ backgroundColor: " rgb(10, 76, 160)" }} onClick={onClickAddPublisher}><span><i className="bi bi-plus"></i></span>Add Publisher</button>
                            </div>
                        </div>
                        <div className="col-4 mb-3 d-flex">
                            <input onKeyUp={handleKeyPress} type="text" className="form-control me-3" ref={searchInput} />
                            <button className='btn btn-primary' onClick={changeSearchInput}>Search</button>
                        </div>
                        <div className='col-md-12 table-responsive'>
                            <table className="table table-hover border" style={{ maxHeight: "50vh" }} >
                                <thead className='text-white' style={{ backgroundColor: " rgb(10, 76, 160)" }}>
                                    <tr>
                                        <th>Publisher Name</th>
                                        <th>Publisher Email</th>
                                        <th>Website</th>
                                        <th>Developer Name</th>
                                        <th>Developer Email</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {loading &&
                                    <>
                                        <div className="spinner-overlay">
                                            <div className="spinner-border position-absolute " style={{ marginTop: "20px", left: "55%" }} role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    <tbody >
                                        {publisherData?.length > 0 && publisherData?.map((el: any, index: number) => (
                                            <tr className='' key={index}>
                                                <td className='text-primary ' style={{ cursor: "pointer" }} onClick={(e) => { onClickPublisherNameNavigation(el) }}>{el?.name}</td>
                                                <td>{el.email}</td>
                                                <td>{el?.website}</td>
                                                <td>{el?.developer_name}</td>
                                                <td>{el?.developer_email}</td>
                                                <td>
                                                    <div className='d-flex justify-content-center cursor-pointer'>
                                                        <button className='bg-transparent border-0 mx-1' onClick={(e) => deletePublisher(el)}><i className="bi bi-trash text-danger"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {publisherData?.length == 0 && (
                                            <div className='text-center mx-auto float-center d-flex justify-content-center my-5'>No records found</div>
                                        )}
                                    </tbody>
                                }
                            </table>
                            {publisherData?.length > 0 && (
                                <div className='d-flex justify-content-end'>
                                    <Pagination totalItems={totalRecords} onPageChange={setCurrentPage} itemsPerPage={itemsPerPage} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
