import React, { useEffect, useRef, useState } from 'react'
import FormBuilder, { FormBuilderData } from '../components/form-builder';
import { DeleteeGame, AllCatagoryList, GameService } from '../app/service/game.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MediaUploadReq } from '../app/model/media-upload.req';
import appConfig from '../config/constant';
import { PublishService } from '../app/service/publisher.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import { S3Helper } from '../app/utility/s3-helper';

const AddGame: React.FC = () => {
    let { id } = useParams<{ id: string }>();
    const gameUserId = id as string;
    const [currentParentsData, setCurrentParentsData] = useState<any>({});
    const [gameDataByID, setGameDataByID] = useState<any>({});
    const [iconPreviewImage, setIconPreviewImage] = useState<any>(null);
    const [availableWallPosters, setAvailableWallPosters] = useState<any[]>([]);
    const [availablePosterFiles, setAvailablePosterFiles] = useState<any[]>([]);
    const [promoVideoPreview, setPromoVideoPreview] = useState<any>("");
    const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
    const [videoData, setVideoData] = useState<any>(null);
    const [posterResponseUrl, setPosterResponseUrl] = useState<any>();
    const navigation = useNavigate();
    const [resValidationError, setResValidationError] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [videoLoading, setVideoLoading] = useState<boolean>(false);
    const [gameCategory, setgameCategory] = useState<any>();
    const [ischecked, setIsChecked] = useState<any>(currentParentsData?.status == 1);
    const [zipFile, setZipFile] = useState<any>();
    const [videoFile, setVideoFile] = useState<any>();
    // const [imageFile, setImageFile] = useState<any>();
    const [iconFile, setIconFile] = useState<any>();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isIconHovering, setIsIconHovering] = useState<boolean>(false)
    const [publisherData, setPublisherData] = useState<any>([]);
    const [selectedPublisherId, setSelectedPublisherId] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<any>('')
    const [ShowDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState<boolean>(false)
    const [gamePreviewData, setGamePreviewData] = useState<any>();
    const [parentsData, setParentsData] = useState<FormBuilderData>(
        {
            errors: [],
            isValid: false,
            value: {},
        }
    );
    const singlePlayerMode = useRef(null);
    const multiPlayerMode = useRef(null);
    const timerMode = useRef(null);
    const scorebarMode = useRef(null);

    useEffect(() => {
        if (gameUserId) {
            loadGame();
        } else {
            setCurrentParentsData([])
        }
        gameCatageriList()
        loadPublishers();
    }, []);

    const loadGame = async () => {
        const data: any = await GameService.getGameById(gameUserId);
        if (gameUserId) {
            data.isValid = true;
            setGameDataByID(data);
            // setWallPosterPreview(appConfig?.IMAGE_URL + data?.wall_imgs);
            setIconPreviewImage(appConfig?.IMAGE_URL + data?.icon);
            setPromoVideoPreview(appConfig?.IMAGE_URL + data?.video);
            setVideoData(data?.video)
            setCurrentParentsData(data);
        } else {
            data.isValid = false;
        }
        console.log("game data by id : ", data);

    }

    const loadPublishers = () => {
        setLoading(true);
        PublishService.GetPublishers(0, 999, '').then((res: any) => {
            const preparedData = res.records.map((publisher: any) => ({
                label: publisher._id,
                value: publisher.name
            }));
            setPublisherData(preparedData);
            setLoading(false);
        })
        console.log("PublsihersData at add game page", publisherData);
    }

    function gameCatageriList() {
        AllCatagoryList().then((res: any) => {
            if (res.status === "fail") {
            } else {
                setgameCategory(res);
            }
        })
    }

    const handleInput = (data: any) => {
        data.value = { ...currentParentsData, ...data.value };
        console.log(data, 'handleInput');
        setParentsData(data);
        // const errors: any = FormValidator(formValidations, data.value);
        setResValidationError(``);
        // setValidationErrors(errors);
    };

    const onChangeVideo = async (event: any) => {

        setVideoFile(event.target.files[0]);
        const file = event.target.files[0];
        if (file) {
            setVideoFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setVideoPreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }

    const onChangeImage = (event: any) => {
        if (event) {
            const mPosters = [...availableWallPosters];
            const mPosterFiles = [...availablePosterFiles];
            for (let index = 0; index < event.target.files.length; index++) {
                const elementFile = event.target.files[index];

                const fileReader = new FileReader();
                fileReader.onload = () => {
                    mPosters.push(fileReader.result);
                };
                fileReader.readAsDataURL(elementFile);
                mPosterFiles.push(elementFile);
            }
            setAvailableWallPosters(mPosters);
            setAvailablePosterFiles(mPosterFiles);
            return true;
        }
    }

    const onChangeIconFile = (e: any) => {
        const file = e.target.files[0];
        setIconFile(e.target.files[0]);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setIconPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setIconPreviewImage(null);
        }
    };

    const removeIconImage = () => {
        setIconPreviewImage(null);
        setIconFile(null);
    }

    const handleUpdateIconImage = () => {
        document.getElementById("icon")?.click();
        setIconPreviewImage(null);
        setIconFile(null);
    };

    const onChangeZipFile = (event: any) => {
        if (event) {
            // const zipFileData = event.target.files[0];
            setZipFile(event.target.files[0]);
            // const payloadData = {
            //     mediaUploadType: zipFileData?.type,
            //     fileName: zipFileData?.name
            // }
            // if (zipFileData) {
            //     FileUpload(payloadData).then((res: any) => {
            //         setUploadedZipData(res)
            //     }).catch((err) => {
            //         console.error(err);
            //     })
            // }
            return true;
        }
    }

    const deleteGame = () => {
        setShowDeleteConfirmationPopup(true);
    }

    const onClickDeleteConfirmation = () => {
        DeleteeGame(gameUserId).then((res: any) => {
            setShowDeleteConfirmationPopup(false);
            navigation('/dashboard/gameList')
        }).catch((e) => {
            setShowDeleteConfirmationPopup(false);
            console.error(e);
            setResValidationError(`No Record Found`)
        })
    }


    const onhandleChange = (event: any) => {
        let data = { ...parentsData.value };
        let isStatus = event === true ? "1" : "0";
        if (currentParentsData) {
            currentParentsData.status = isStatus;
            console.log("status:-->", currentParentsData.status, isStatus);
        } else {
            data.status = isStatus
        }
        setIsChecked(isStatus);
    };

    const handleCategoryChange = (event: any) => {
        setSelectedCategory(event.target.value);
    }

    const handlePublisherChange = (event: any) => {
        setSelectedPublisherId(event.target.value);
    }

    const handleSubmit = async () => {
        setIsFormSubmitted(true);
        const data = parentsData.value ? { ...parentsData.value } : { ...currentParentsData };
        if (gameUserId) {
            parentsData.isValid = true;
            setCurrentParentsData(data);
        }
        setGamePreviewData(data);
        data.name = ((data?.name)?.trim())?.toLowerCase();
        data.status = ischecked
        const errors = parentsData.errors;

        if (parentsData.isValid) {
            setLoading(true);
            const wallPosterUrls = [];
            if (availablePosterFiles && availablePosterFiles.length) {
                for (let index = 0; index < availablePosterFiles.length; index++) {
                    const wallPoster = availablePosterFiles[index];
                    const payloadData: MediaUploadReq = {
                        mediaUploadType: 'wall-poster',
                        fileName: wallPoster?.name
                    }
                    let bannerUploadUrl = await GameService.getMediaUploadUrl(payloadData);
                    wallPosterUrls.push(bannerUploadUrl?.path);
                    await GameService.uploadMediaToUrl(bannerUploadUrl?.url, wallPoster, wallPoster?.type);
                }
            }
            data.wall_imgs = wallPosterUrls;

            let gameVideoFilePath;
            if (videoFile) {
                const payloadData: MediaUploadReq = {
                    mediaUploadType: 'promo-video',
                    fileName: videoFile?.name
                }
                setVideoLoading(true);
                setTimeout(() => {
                    setVideoLoading(false)
                }, 2000);
                gameVideoFilePath = await GameService.getMediaUploadUrl(payloadData)
                await GameService.uploadMediaToUrl(gameVideoFilePath?.url, videoFile, videoFile?.type);
                data.video = gameVideoFilePath?.path || '';
            }

            let gameIconFileRes;
            if (iconFile) {
                const iconPayload: MediaUploadReq = {
                    mediaUploadType: 'game-icon',
                    fileName: iconFile?.name
                }
                gameIconFileRes = await GameService.getMediaUploadUrl(iconPayload);
                await GameService.uploadMediaToUrl(gameIconFileRes?.url, iconFile, iconFile?.type);
                data.icon = gameIconFileRes.path;
            } else {
                // If no new image file is selected, include the existing icon URL in the payload
                // formData.append("icon", gameDataByID?.icon || ""); // Assuming gameDataByID?.icon contains the existing icon URL
            }



            // formData.append("name", data.name || currentParentsData?.name);
            // formData.append("category", data.category || currentParentsData?.category);
            // formData.append("subcategory", data.subcategory || currentParentsData?.subcategory);
            // formData.append("publisher", data.publisher || currentParentsData?.publisher);
            // formData.append("created", data.created || currentParentsData?.created);
            // formData.append("description", data.description || currentParentsData?.description);
            // formData.append("how_to_play", data.how_to_play || currentParentsData?.how_to_play);
            // // formData.append("levels", data.levels);
            // formData.append("maximum_game_time", data.maximum_game_time ? data.maximum_game_time : "90" || currentParentsData?.maximum_game_time);
            // formData.append("video_urls", data.video_urls || currentParentsData?.video_urls);
            // // formData.append("zipfile", gameZipFileRes?.path as string);
            // formData.append("zipfile", zipFile ? zipFile : "");
            // formData.append("status", data?.status || currentParentsData?.status || ischecked ? "1" : "0");
            // formData.append("video", gameVideoFilePath?.path as string || currentParentsData?.video);
            // formData.append("icon", gameIconFileRes?.path as string || currentParentsData?.icon);
            // formData.append("wall_imgs", JSON.stringify(wallPosterUrls) || currentParentsData?.wall_imgs);//`${url}${imageFile}`
            // formData.append("external_link", data.external_link || currentParentsData?.external_link);
            // formData.append("type", zipFile ? '1' : '2');
            const game_modes: number[] = [];
            addMode(singlePlayerMode, 1, game_modes);
            addMode(multiPlayerMode, 2, game_modes);
            addMode(timerMode, 3, game_modes);
            addMode(scorebarMode, 4, game_modes);

            data.game_modes = game_modes;
            if (gameUserId) {
                GameService.updateGame(gameUserId, data).then((res: any) => {
                    setIsFormSubmitted(true);
                    navigation('/dashboard/gameList')
                }).catch((e) => {
                    console.error(e);
                    // console.log("UpdatedGame:-->", Object.fromEntries(formData));
                    setResValidationError(`Game already existed`)
                })
            }
            else {
                const gameId = await GameService.addNewGame(data);
                if (zipFile) {
                    const formData = new FormData();
                    formData.append('_id', gameId);
                    formData.append("zipfile", zipFile);
                    await GameService.uploadNewZipFile(gameId, formData);
                }
                setLoading(false);
                navigation('/dashboard/gameList');

                // GameAdd(Object.fromEntries(formData)).then((res: any) => {
                //     navigation('/dashboard/gameList')
                //     setLoading(false);
                // }).catch((err) => {
                //     console.error(err);
                //     setLoading(false);
                //     // console.log("AddedGame:-->", Object.fromEntries(formData));
                //     setResValidationError(`Game already added`)
                // })
            }
        }
    }

    const addMode = (mode: any, value: number, game_modes: number[]) => {
        if (mode && mode.current) {
            if ((mode.current as HTMLInputElement)?.checked) {
                game_modes.push(value);
            }
        }
    }

    const onDeleteWallPoster = (index: number) => {
        const wallPosters = [...availableWallPosters];
        wallPosters.splice(index, 1);
        setAvailableWallPosters(wallPosters);
    }

    return (
        <>
            <div className="container position-relative">
                <div className="row vh-100-all">
                    {loading &&
                        <>
                            <div className="spinner-overlay">
                                <div className="spinner-border " style={{ position: "absolute", marginTop: "250px" }} role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        </>
                    }
                    {!loading && (<>
                        <div className="col-12 col-lg-7 px-md-5 mt-4 justify-content-center  align-items-center sx-bg-page position-relative ">
                            <div className="d-flex w-md-75 px-3 px-md-5 mx-auto mx-md-5 flex-column shadow p-3 mb-5 bg-white rounded-3 text-start">
                                <FormBuilder onUpdate={handleInput} showValidations={isFormSubmitted}>
                                    <form action="">
                                        <div className="mb-4">
                                            <h2 className='text-center mb-4' style={{ fontFamily: "Montserrat", fontWeight: "bold" }}>Enter Game Details</h2>
                                            <div className="mb-3 ">
                                                <label htmlFor="addGame">Name</label>
                                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                                <input type="text" name="name" id="name" className="form-control" defaultValue={currentParentsData?.name} placeholder="Name" data-validate-required="Please enter game name" data-validate-name="name" />
                                                {/* {nameError && (<p className="text-danger gameLabel_form_label"> {nameError}</p>)} */}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Category</label>
                                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                                <select name="category" className="form-select form-control  mb-0"
                                                    onChange={handleCategoryChange}
                                                    value={selectedCategory ? selectedCategory : currentParentsData?.category} data-validate-required="Please select category" >
                                                    <option value="" className='form-control'>Select category</option>
                                                    {gameCategory?.map((name: any, i: number) => (
                                                        <option key={i} value={name} >
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <div>
                                                    <label className="form-label mb-0">Features</label>
                                                </div>
                                                <div className="form-check">
                                                    <input ref={singlePlayerMode} defaultChecked name='game_modes' className="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Single Player</label>
                                                </div>
                                                <div className="form-check">
                                                    <input ref={multiPlayerMode} name='game_modes' className="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Multiplayer</label>
                                                </div>
                                                <div className="form-check">
                                                    <input ref={timerMode} name='game_modes' className="form-check-input" type="checkbox" id="inlineCheckbox3" value="3" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Timer</label>
                                                </div>
                                                <div className="form-check">
                                                    <input ref={scorebarMode} name='game_modes' className="form-check-input" type="checkbox" id="inlineCheckbox4" value="4" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Scorebar</label>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label mb-0">Publisher</label>
                                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                                <select
                                                    name="publisher"
                                                    id="publisher"
                                                    className="form-select"
                                                    value={selectedPublisherId ? selectedPublisherId : currentParentsData?.publisher}
                                                    onChange={handlePublisherChange}
                                                >
                                                    <option value="">Select Publisher</option>
                                                    {publisherData.map((publisher: any) => (
                                                        <option key={publisher.label} value={publisher?.label}>{publisher?.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className="mb-3">
                                            <label className="form-label mb-0">Created</label>
                                            <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                            <input type="date" name="created" id="created" className="form-control" defaultValue={currentParentsData.created} placeholder="Created" data-validate-required="Please choose the date" />
                                             </div> */}
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Description</label>
                                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                                <textarea className="form-control" name="description" id="description" defaultValue={currentParentsData?.description} placeholder="Description" data-validate-required="Please enter description"
                                                    rows={3}
                                                    cols={52}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label mb-0">How to play</label>
                                                <textarea className="form-control" name="how_to_play" id="description" defaultValue={currentParentsData?.how_to_play} placeholder="Please enter how to play the game" data-validate-required="Please enter how to play the game details"
                                                    rows={2}
                                                    cols={52}
                                                />
                                            </div>
                                            {videoLoading &&
                                                <>
                                                    <div className="spinner-overlay">
                                                        <div className="spinner-border " style={{ marginTop: "250px" }} role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {!videoLoading && (
                                                <div className="mb-3">
                                                    <label className="form-label mb-0">Video</label>
                                                    <input type="file" name="video" id="video" className="form-control" accept=".avi,.mpg,.mp2,.mpeg,.mpe,.mpv,.ogg,.webm,.mp4,.m4p,.m4v,.mov,.qt,.wmv,.flv,.swf" onChange={(e) => { onChangeVideo(e) }} placeholder="Video" />
                                                    {/* value={currentParentsData?.video} */}
                                                </div>
                                            )}

                                            {/* <div className="mb-1">
                                                <label className="form-label mb-0">Levels</label>
                                                <input type="text" name="levels" id="levels" className="form-control" defaultValue={currentParentsData.levels} placeholder="Levels" />
                                            </div> */}

                                            <div className="mb-1">
                                                <label className="form-label mb-0">Maximum Game Time</label>
                                                <input type="number" name="maximum_game_time" id="maximum_game_time" className="form-control" defaultValue={currentParentsData?.maximum_game_time ? currentParentsData?.maximum_game_time : 90} placeholder="Levels" />
                                            </div>

                                            {/* <div className="mb-3">
                                                <label className="form-label mb-0">Wall Poster</label>
                                                <input type="file" name="wall_imgs" id="wall_imgs" className="form-control" accept=".jpg,.png,.svg" onChange={(e) => { onChangeImage(e) }} defaultValue={currentParentsData.wall_imgs} placeholder="Wall Imgs" />
                                            </div> */}
                                            {/* <>{appConfig?.IMAGE_URL + currentParentsData?.wall_imgs}</> */}
                                            <label className='my-2'>Wall posters</label>
                                            <div className={`d-flex cursor-pointer justify-content-center my-auto align-items-center border rounded border-secondary border-dashed`}>
                                                <div
                                                    className='file_uploader text-center d-flex flex-column justify-content-center align-items-center'
                                                    onMouseEnter={() => setIsHovering(true)}
                                                    onMouseLeave={() => setIsHovering(false)}
                                                >
                                                    {availableWallPosters && availableWallPosters.length > 0 &&
                                                        <div className="d-grid p-3" style={{ gridTemplateColumns: '1fr 1fr', gridGap: 20 }}>
                                                            {availableWallPosters.map((el, posterIndex) =>
                                                                <div className="border p-2 d-flex align-items-center justify-content-center position-relative">
                                                                    <img src={el} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                                    <span onClick={() => onDeleteWallPoster(posterIndex)} className='position-absolute border btn btn-danger d-flex align-items-center justify-content-center'
                                                                        style={{ borderRadius: '50%', width: 40, height: 40 }}>
                                                                        <FontAwesomeIcon className=' text-white ' style={{ fontSize: 15 }} icon={faTrash}></FontAwesomeIcon>
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                    {/* {!wallPosterPreview && currentParentsData?.wall_imgs !== "undefined" && gameDataByID?.wall_imgs !== "undefined" && (
                                                            <div>
                                                                <p className='mb-2 f14 text-dark cursor-pointer'>Upload a wall poster with extension .jpg, .png, .svg files</p>
                                                                <span className="btn btn-primary rounded-2 custom-file-upload">
                                                                    Upload Wall Poster
                                                                </span>
                                                            </div>
                                                        )} */}
                                                    {availableWallPosters.length < 1 && <label htmlFor='wall_imgs'>
                                                        <i className="bi bi-cloud-upload fs-1 cursor-pointer"></i>
                                                        <p className='mb-2 f14 text-dark cursor-pointer'>Upload a wall poster with extension .jpg, .png, .svg files</p>
                                                        <span className="btn btn-primary rounded-2 custom-file-upload">
                                                            Upload Wall Poster
                                                        </span>
                                                    </label>}

                                                    <input multiple accept='image/*' id="wall_imgs" name='wall_imgs' type="file" onChange={(e) => { onChangeImage(e) }} style={{ display: "none" }} />
                                                    {/* {wallPosterPreview && (
                                                        <div className={` my-2 ${isHovering ? "poster-hover-button-position" : ""}`}>
                                                            <button className='btn btn-primary mx-2' onClick={handleUpdateImage}>Update Poster</button>
                                                            <button className="btn btn-danger text-white" onClick={removePosterImage}>Remove Poster</button>
                                                        </div>
                                                    )} */}
                                                </div>
                                            </div>

                                            {/* <div className="mb-3">
                                            <label className="form-label mb-0">Video Urls</label>
                                            <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                            <input type="text" name="video_urls" id="video_urls" className="form-control" defaultValue={currentParentsData.video_urls} placeholder="Video Urls" data-validate-required="Please insert video url" />
                                        </div> */}

                                            {/* <div className="mb-3">
                                            <label className="form-label mb-0">Icon</label>
                                            <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                            <input type="file" name="icon" id="icon" className="form-control" accept=".jpg,.png,.svg" defaultValue={currentParentsData.icon} placeholder="Icon" onChange={(e) => { onChangeIconFile(e) }} />
                                        </div> */}
                                            <label className='my-2'>Icon </label>
                                            <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                            <div className={`d-flex cursor-pointer justify-content-center my-auto align-items-center border rounded border-secondary border-dashed my-2 ${iconPreviewImage ? "my-2" : "py-3"}`}>
                                                <div
                                                    className='file_uploader text-center d-flex flex-column justify-content-center align-items-center'
                                                    onMouseEnter={() => setIsIconHovering(true)}
                                                    onMouseLeave={() => setIsIconHovering(false)}
                                                >
                                                    <label htmlFor="icon" className='cursor-pointer' style={{ cursor: "pointer" }}>
                                                        <div id="imagePreview ">
                                                            {iconPreviewImage ? (
                                                                <img src={iconPreviewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%', cursor: "pointer" }} />
                                                            ) : (
                                                                <i className="bi bi-cloud-upload fs-1 cursor-pointer"></i>
                                                            )}
                                                        </div>
                                                        {!iconPreviewImage && (
                                                            <div>
                                                                <p className='mb-2 f14 text-dark cursor-pointer'>Upload a icon with extension .jpg, .png, .svg files</p>
                                                                <span className="btn btn-primary rounded-2 custom-file-upload">
                                                                    Upload Icon
                                                                </span>
                                                            </div>
                                                        )}
                                                    </label>
                                                    <input id="icon" name='icon' type="file" onChange={(e) => { onChangeIconFile(e) }} style={{ display: "none" }} />
                                                    {iconPreviewImage && (
                                                        <div className={` my-2 ${isIconHovering ? "poster-hover-button-position" : ""}`}>
                                                            <button className='btn btn-primary mx-2' onClick={handleUpdateIconImage}>Update Icon</button>
                                                            <button className="btn btn-danger text-white" onClick={removeIconImage}>Remove Icon</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {/* <div className="mb-3">
                                                <label className="form-label mb-0">Icon</label>
                                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                                <input
                                                    type="file"
                                                    name="icon"
                                                    id="icon"
                                                    className="form-control"
                                                    accept=".jpg,.png,.svg"
                                                    defaultValue={currentParentsData.icon}
                                                    placeholder="Icon"
                                                    onChange={(e) => onChangeIconFile(e)}
                                                />
                                                {previewImage && (
                                                    <img
                                                        src={previewImage}
                                                        alt="Preview"
                                                        style={{ maxWidth: '100%', marginTop: '10px' }}
                                                    />
                                                )}
                                            </div> */}
                                            <div className="my-3">
                                                <label className="form-label mb-0">Upload zip file</label>
                                                <input type="file" placeholder="Cargar" accept=".zip" className="form-control" name="zipfile" id="zipfile" onChange={(e) => { onChangeZipFile(e) }} />
                                                {/* value={currentParentsData?.zipfile} */}
                                            </div>

                                            <div className="my-3">
                                                <label className="form-label mb-0">Game link</label>
                                                <input type="text" placeholder="" className="form-control" name="external_link" id="external_link" />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label mb-0 col-5">Game Publish:</label>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={(e) => { onhandleChange(e.target.checked) }} onBlur={(e) => { onhandleChange(e.target.checked) }} name="status" value={currentParentsData?.status} checked={currentParentsData?.status == "1" ? true : false} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </form>
                                </FormBuilder>
                                {resValidationError && <p className="text-danger">{resValidationError}</p>}
                                <div className='form-footer border-top border-primary py-3 text-center mt-3'>
                                    <div className="row vh-100-all">
                                        <div className={`${gameUserId ? "col-6 " : "col-12"}`}>
                                            <button type="submit" disabled={loading} className='btn btn-primary mb-1' onClick={handleSubmit} style={{ fontFamily: "Montserrat", fontWeight: "bold" }}> Submit Game </button>
                                        </div>
                                        {gameUserId && <div className='col-6'>
                                            <button type="submit" className='btn btn-danger mb-1' onClick={deleteGame} style={{ fontFamily: "Montserrat", fontWeight: "bold" }}> Delete Game  </button>
                                        </div>}
                                        <div className={`${gameUserId ? "col-12 my-2" : "px-1 my-2"}`}>
                                            <Link to="../gameList"><button className={`btn btn-secondary py-1 mx-2 my-2 my-md-0 px-5 ${gameUserId ? "" : "px-5 "}`} style={{ fontFamily: "Montserrat", fontWeight: "bold", height: "37px" }}>Cancel</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 d-lg-block bg-danger p-3 mb-5 rounded-3  mt-4 text-start">
                            <h2 className='text-center mb-4 text-white' style={{ fontFamily: "Montserrat", fontWeight: "bold", }}>Game Overview</h2>
                            <div className='row preview-container text-white'>
                                <div className='col-md-12 mx-auto'>
                                    {/* {currentParentsData?.icon ?
                                        <img src={currentParentsData?.icon ? currentParentsData?.icon : ""} className='' alt="Icon" style={{ maxWidth: '100%', marginTop: '10px' }} />
                                        : <>{currentParentsData?.icon}</>
                                        } */}
                                </div>
                                <div className='col-md-12 mx-auto my-3'>
                                    <video controls loop autoPlay className='item rounded shot-video w-100 mx-auto'>
                                        {/* <source src={currentParentsData?.video ? currentParentsData?.video : gamePreviewData?.video?.name} type="video/mp4" /> */}
                                        <source src={promoVideoPreview ? promoVideoPreview : gamePreviewData?.video?.name} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            {
                                <span className='p-5 text-white'>
                                    <p className="details-item"><label className='w-50 fw-bold'>Game Name</label>  <span className='text-light w-50'> : {currentParentsData?.name ? currentParentsData?.name : parentsData?.value?.name ? parentsData?.value?.name : ""} </span></p>
                                    <p className="details-item"><label className='w-50 fw-bold'>Category </label>  <span className='text-light w-50'> :  {currentParentsData?.category ? currentParentsData?.category : parentsData?.value?.category ? parentsData?.value?.category : ""} </span></p>
                                    <p className="details-item"><label className='w-50 fw-bold'>Publisher  </label>  <span className='text-light w-50'> : {currentParentsData?.publisher ? currentParentsData?.publisher : parentsData?.value?.publisher ? parentsData?.value?.publisher : ""} </span></p>
                                    <p className="details-item"><label className='w-50 fw-bold'>Description  </label>  <span className='text-light w-50'>:  {currentParentsData?.description ? currentParentsData?.description : parentsData?.value?.description ? parentsData?.value?.description : ""} </span></p>
                                    {/* <p className="details-item"><label className='w-50 fw-bold'>Levels  </label>  <span className='text-light w-50'> : {currentParentsData?.levels ? currentParentsData?.levels : parentsData?.value?.levels ? parentsData?.value?.levels : ""} </span></p> */}
                                    {gameUserId && (
                                        <div>
                                            {/* <p className="details-item"><label className='w-50 fw-bold'>Wall Poster  </label>
                                                <span className='text-light w-50 fw-bold'> : <img src={currentParentsData?.wall_imgs ? currentParentsData?.wall_imgs : posterResponseUrl?.path ? posterResponseUrl?.path : ""} /></span></p>
                                            <p className="details-item"><label className='w-50 fw-bold'>Created  </label>  <span className='text-light w-50'> : {currentParentsData?.created ? currentParentsData?.created : ""} </span></p>
                                            <p className="details-item"><label className='w-50 fw-bold'>Likes</label><span className='text-light w-50'> : {currentParentsData?.likes ? currentParentsData?.likes : ""}</span></p>
                                            <p className="details-item"><label className='w-50 fw-bold'>Shares</label><span className='text-light w-50'> : {currentParentsData?.shares ? currentParentsData?.shares : ""}</span></p>
                                            <p className="details-item"> <label className='w-50 fw-bold'>Comments </label>  <span className='text-light w-50'> : {currentParentsData?.comments ? currentParentsData?.comments : ""} </span></p>
                                            <p className="details-item"><label className='w-50 fw-bold'>Play Count </label>  <span className='text-light w-50'>  : {currentParentsData?.play_count ? currentParentsData?.play_count : ""} </span></p> */}
                                            <p className="details-item"><label className='w-50 fw-bold'>Game Publish </label>  <span className='text-light w-50'> : {currentParentsData?.status == "0" ? "Un Published" : "Published"} </span></p>
                                        </div>)}
                                </span>
                            }
                        </div>
                    </>)}
                    {ShowDeleteConfirmationPopup &&
                        <>
                            <div className="modal-backdrop fade show"></div>
                            <div className={`modal fade shadow  text-dark ${ShowDeleteConfirmationPopup ? "show " : "false"}`}
                                style={{ display: ShowDeleteConfirmationPopup ? "block" : "none" }}
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog  modal-md modal-dialog-centered ">
                                    <div className="modal-content">
                                        <div className="modal-header border-bottom-0">
                                        </div>
                                        <div className="modal-body text-dark py-4">
                                            <div className='d-flex justify-content-center py-4'>
                                                Are you sure want to delete the <span className='fw-bold fs-6 mx-1'>{currentParentsData.name ? currentParentsData.name : "game"} </span> ?
                                            </div>
                                            <div className='modal-footer border-bottom-0 border-top-0'>
                                                <button className='btn btn-primary px-4 rounded-pill px-3 cursor-pointer' style={{ backgroundColor: "#0a4ca0" }} onClick={onClickDeleteConfirmation}>Yes</button>
                                                <button className='btn btn-secondary px-2 rounded-pill px-3' onClick={() => setShowDeleteConfirmationPopup(false)}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default AddGame;