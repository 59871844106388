import { Game } from "../model/game";
import { MediaUploadResponse } from "../model/media-upload.res";
import API from "../utility/axios";

export class GameService {
    static getGameById(id: string): Promise<Game> {
        return API.get(`/game/${id}`);
    }

    static getAllGames(): Promise<Game[]> {
        return API.get(`/game`);
    }

    static getBannerUploadUrl(): Promise<MediaUploadResponse> {
        return API.get(`game/poster-url`);
    }

    static uploadMediaToUrl(url: string, data: any, type: string): Promise<void> {
        console.log('data --------------- ', data, type);

        return API.put(url, data, {
            headers: {
                'Content-Type': type
            },
            transformRequest: [(data) => data],
        });
    }

    static getMediaUploadUrl = (data: any): Promise<MediaUploadResponse> => {
        return API.post(`/media/upload-url`, data);
    }

    static addNewGame(data: any): Promise<string> {
        return API.post(`/game`, data);
    }

    static uploadNewZipFile = (id: string, data: any): Promise<string> => {
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        return API.put(`/game/file/${id}`, data, config);
    }

    static updateGame(id: any, data: any): Promise<any> {
        return API.put(`/game/${id}`, data);
    }
}
export const AllGames = (): Promise<any> => {
    return API.get(`/game`);
}

export const UpdateGame = (id: any, data: any): Promise<any> => {
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return API.put(`/game/${id}`, data, config);

    // return API.put(`/game/${id}`, data);
}

export const DeleteeGame = (id: any): Promise<any> => {
    return API.delete(`/game/${id}`);
}

export const GetOneRecordData = (id: any): Promise<any> => {
    return API.get(`/game/${id}`);
}

export const AllCatagoryList = (): Promise<any> => {
    return API.get(`/lookUp/category`);
}
