// export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_URL = "https://moplay-website.s3.ap-south-1.amazonaws.com/"

const appConfig = {
  BASE_URL: 'https://api.moplay.in',//process.env.REACT_APP_BASE_URL,
  // BASE_URL: 'http://localhost:4211',
  IMAGE_URL:IMAGE_URL,
  SOCKET_URL: 'https://api.moplay.in'
};
console.log('config ', process.env);

export default appConfig;
