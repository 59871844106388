import React from 'react'
import { NavLink } from 'react-router-dom';

interface Props {
    activeMenu?: any;
}

export const SideMenu = (props: Props) => {
    return (
        <div className='position-fixed bg-white h-100 text-dark border-end text-start' style={{ top: 60, width: '20%', cursor: "pointer" }}>
            <ul className='mt-3' style={{ listStyle: 'none' }}>
                <li style={{ padding: '10px' }}>
                    <NavLink to="gameList">Manage Games</NavLink>
                </li>
                <li style={{ padding: '10px' }}>
                    <NavLink to="manage-publisher">Manage Publishers</NavLink>
                </li>
                <li style={{ padding: '10px' }}>
                    <NavLink to='game-reports'>Game Reports</NavLink>
                </li>
                <li style={{ padding: '10px' }}>
                    <NavLink to='challenge-reports'>Challenge Reports</NavLink>
                </li>
                <li style={{ padding: '10px' }}>
                    <NavLink to='user-reports'>User Reports</NavLink>
                </li>
                <li style={{ padding: '10px' }}>
                    <NavLink to='blog'>Blogs</NavLink>
                </li>
            </ul>
        </div>
    )
}
