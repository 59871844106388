import React, { useEffect, useState, MouseEvent } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Search } from "../app/service/search.service";
import NODATA_ICON from "../assets/images/icons/noresultsFound.png";
import { GameService } from '../app/service/game.service';
import { Header } from '../components/header';
import { SideMenu } from '../components/side-menu';
import Pagination from '../components/pagination';


export default function GameListView() {
    const [gameData, setGameData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const [search, setSearch] = useState('');
    const navigation = useNavigate();
    // const [currentPageData, setCurrentPageData] = useState([]);
    const [itemsPerPage, setItemPerPage] = useState<number>(20);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, gameData.length);

    useEffect(() => {
        gameShow();
        // searchGame()
    }, []);

    useEffect(() => {
        if (gameData.length > 0) {
            if (currentPage == 1) {
                handlePageChange(1);
            }
        }
    }, [gameData]);

    function gameShow() {
        setLoading(true);
        GameService.getAllGames().then((res: any) => {
            setGameData(res);
            setLoading(false);
        })
    }

    function showGameDetails(data: any) {
        if (data === null) {
            localStorage.setItem("Id", "");
        } else {
            localStorage.setItem("Id", data._id);
        }
    }

    const getSearchData = (data: any) => {
        if (data) {
            onSearch(data);
        }
    }

    function onSearch(event: any) {
        Search(event).then((res: any) => {
            setSearch(event);
        })
    }
    const onClickSignout = () => {
        navigation("/")
    }

    const onClickGameNameNavigation = (data: any) => {
        if (data?.name) {
            navigation(`../gameUser/${data?._id}`)
        } else {
            navigation(`/gameList`)
        }
    }

    const handlePageChange = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        setLoading(true);
        setTimeout(() => {
            const startIndex = (pageNumber - 1) * itemsPerPage;
            console.log("sartIndex : ", startIndex);
            const endIndex = startIndex + itemsPerPage;
            console.log("sartIndex : ", endIndex);
            // setCurrentPageData(gameData.slice(startIndex, endIndex));
            setLoading(false);
        }, 500);
        // console.log("currentPage data : ", currentPageData)
    };


    return (
        <div>
            <div className="container-fluid my-2">
                {/* {gameData.filter((item: any) => item.name?.toLowerCase().includes(search)).map(
                        (Data: any) =>
                            <Link to={`/gameUser/${Data._id}`} key={Data._id} className='cuscursor-pointer text-decoration-none mt-3 rounded' style={{ fontFamily: "Montserrat", fontWeight: "bold" }} onClick={() => showGameDetails(Data)}>
                                <h5>{Data.name.toUpperCase()} </h5>
                                <span className="d-flex mx-4">
                                    <video controls loop autoPlay className='item rounded shot-video w-100 mx-auto'>
                                        <source src={Data.video} type="video/mp4" />
                                    </video>
                                </span>
                            </Link>
                    )} */}
                <div className='row'>
                    <div className='col-md-12 my-3 d-flex justify-content-between'>
                        <div className='fs-4  fw-bold text-start'>Games</div>
                        <div className=''>
                            <Link to="../game"><button className='btn btn-primary' style={{ backgroundColor: " rgb(10, 76, 160)" }} ><span><i className="bi bi-plus"></i></span>Add Game</button></Link>
                        </div>
                    </div>
                    <div className='col-md-12 table-responsive'>
                        <table className="table table-hover border">
                            <thead className='text-white' style={{ backgroundColor: " rgb(10, 76, 160)" }}>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            {loading &&
                                <>
                                    <div className="spinner-overlay">
                                        <div className="spinner-border position-absolute " style={{ marginTop: "20px", left: "55%" }} role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                </>
                            }
                            {!loading && (
                                <tbody>
                                    {gameData?.length > 0 && gameData.filter((item: any) => item.name?.toLowerCase().includes(search))?.map((el: any, index: number) => (
                                        <tr className='' key={index}>
                                            <td className='text-primary' style={{ cursor: "pointer" }} onClick={(e) => { onClickGameNameNavigation(el) }}>{el?.name ? el?.name : "-"}</td>
                                            <td>{el?.category}</td>
                                            <td>{el?.status == "1" ? <div className='text-success'>Published</div> : <div className='text-danger'>Un Published</div>}</td>
                                        </tr>
                                    ))}
                                    {gameData?.length == 0 && (
                                        <div className='text-center mx-auto float-center d-flex justify-content-center my-5'>No records found</div>
                                    )}
                                </tbody>
                            )}
                        </table>
                        {gameData?.length > 0 && (
                            <div className='d-flex justify-content-end'>
                                {/* <div className='mx-2 my-1'>
                                    Showing {rangeStart} - {rangeEnd} of {gameData?.length} items
                                </div> */}
                                <Pagination totalItems={gameData.length} onPageChange={handlePageChange} itemsPerPage={itemsPerPage}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
