import { faArrowAltCircleDown, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

export interface GridDataCol {
    field: string;
    title: string;
    type?: 'link-btn';
    sortable?: boolean;
}

export interface GridDataConfig {
    cols: GridDataCol[];
}

interface Props {
    data: any[];
    config: GridDataConfig;
    onRowClick?: (item: any) => void;
    sortDirection?: number;
    sortKey?: string;
    onChangeSort?: (key: string, direction: number) => void;
}

export const MRDataGrid = (props: Props) => {

    const onRowClick = (item: any) => {
        if (props.onRowClick) {
            props.onRowClick(item);
        }
    }

    const onChangeSort = (el: GridDataCol) => {
        if (props.onChangeSort && el.sortable) {
            props.onChangeSort(el.field, (props.sortDirection || 1) * -1);
        }
    }

    return (
        <table className="table table-hover border" >
            <thead className='text-white' style={{ backgroundColor: " rgb(10, 76, 160)" }}>
                <tr>
                    {
                        props.config.cols.map((el, index) => <th key={index} onClick={() => { onChangeSort(el) }} style={{cursor: el.sortable?'pointer':'auto'}}>
                            {el.title}
                            {
                                el.sortable && props.sortKey === el.field &&
                                <FontAwesomeIcon className='ms-3' icon={props.sortDirection === 1 ? faArrowDown : faArrowUp}></FontAwesomeIcon>
                            }
                        </th>)
                    }
                </tr>
            </thead>

            <tbody >
                {props.data?.length > 0 && props.data?.map((el: any, index: number) => (
                    <tr className='' key={index}>
                        {props.config.cols.map((colData, rowIndex) => <>
                            {colData.type === 'link-btn' && <td style={{ cursor: 'pointer' }} className='text-primary' onClick={() => onRowClick(el)} key={rowIndex}>{el[colData.field]}</td>}
                            {colData.type !== 'link-btn' && <td key={rowIndex}>{el[colData.field]}</td>}
                        </>)}
                    </tr>
                ))}
                {props.data?.length == 0 && (
                    <div className='text-center mx-auto float-center d-flex justify-content-center my-5'>No records found</div>
                )}
            </tbody>
        </table>
    )
}
