import React, { useEffect, useState } from 'react';
import logo from '../assets/images/icons/logo.png';
import FormBuilder from '../components/form-builder';
import { SIGNIN } from '../app/service/login.service';
import Banner from '../assets/images/thembanner.jpg';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PlatformActionType } from '../app/redux/reducer/types';
import { connect } from 'react-redux';
// import { connect } from 'react-redux';

interface Props {
    updateUserLogin?: (data: any) => void;
}

const Login = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [parentsData, setParentsData] = useState<any>({});
    const navigation = useNavigate();
    const [validationError, setValidationError] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const handleLoginInput = (data: any) => {
        setParentsData(data);
    };

    const userFormSubmit = () => {
        const data = { ...parentsData.value };
        setIsFormSubmitted(true);
        if (parentsData.isValid) {
            setLoading(true);
            SIGNIN(data).then((res: any) => {
                if (res._id != null) {
                    console.log('props ', props);
                    
                    if (props.updateUserLogin) {
                        props.updateUserLogin(res);
                    }
                    navigation('/dashboard/gameList');
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch((err) => {
                setLoading(false);
                setValidationError('Enter Valid Email Id or Password');
            })
        } else {
        }
    }
    return (
        <div className="container-fluid">
            <div className="row ">
                <div className="col-sm-12 col-md-5 d-none d-lg-block bg-danger ">
                    <div className='mt-5 pt-5'>
                        <h4 className='text-center mb-3 mt-5 pt-5 label_field text-white title-reg'>Login</h4>
                        <div className="row d-flex justify-content-center mt-3">
                            <div className="col-12 col-lg-12 px-2 mb-4 w-75 justify-content-center d-flex w-50 px-5 mx-5 flex-column shadow p-3 mb-5 bg-white rounded-4">
                                <FormBuilder onUpdate={handleLoginInput} showValidations={isFormSubmitted}>
                                    <form action="">
                                        <div className="form-group mb-4">
                                            <label htmlFor="login_username" className='d-flex justify-content-start'>Email</label>
                                            <input className="form-control" type="text" name="email" placeholder="Email" data-validate-required="Please enter your email" data-validate-email="email Id" />
                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="login_username" className='d-flex justify-content-start' >Password</label>
                                            <input type="password" name="password" id="login_password" className="form-control" placeholder="Password" data-validate-required="Please enter password" />
                                        </div>
                                    </form>
                                </FormBuilder>

                                <button type="submit" className='btn btn-primary mb-1 w-50 mt-4 mx-auto' onClick={userFormSubmit} style={{ fontFamily: "Montserrat", fontWeight: "bold" }} >
                                    {loading &&
                                        <div className="text-center spinner">
                                            <div className="spinner-border spinner-border-sm login-spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                    {!loading &&
                                        <span>Submit</span>
                                    }
                                </button>
                                {validationError && <p className="text-danger admin_form_label d-flex justify-content-start">{validationError}</p>}
                            </div>
                            <div className="">
                                <div className="col-6 mx-auto">
                                    {/* <small className='d-flex justify-content-start text_font float-end'> <span className='mt-2'> New to moPlay? </span> <Link to={'/sign-up'} className='btn btn-light mt-0 mx-4 mb-2 float-end btn-outline-primary' data-toggle="button" aria-pressed="false" > Sign Up </Link>
                                    </small> */}
                                    <div className="d-flex justify-content-center ">
                                        <div className='mt-1 text-white'>New to moPlay?</div>
                                        <div><Link to={'/sign-up'} className='btn btn-light mt-0 mx-4 mb-2 float-end btn-outline-primary' type="button" aria-pressed="false" > Sign Up </Link></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    {/* <button className=''> Sign Up</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 d-none d-lg-block bg-dark ">
                    <div className=" mt-4 w-100 d-flex justify-content-center">
                        <img src={logo} alt="" width={150} height={150} />
                    </div>
                    <div className='col-sm-12 h-50 w-100 mx-auto mt-3 mb-4'>
                        <img src={Banner} alt="" className="w-100 rounded" />
                        {/* width={865} height={382}  */}
                    </div>
                    <h5 className='label_field text-white mt-5'>  Your games help us bring people together.</h5>
                    <p className='text_font text-white mt-4'> Introduce your delightful games to people all</p>
                    <p className='text-white mb-3'>over the world with moPlay!</p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({
    updateUserLogin: (data: any) => { 
        dispatch({ type: PlatformActionType.USER_LOGIN, data }) 
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(Login);