import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom'
import { ReportsService } from '../service/reports.service';
import { PlatformReportRes } from '../model/platform-report.res';

interface Props {
    totalUsers?: number;
}
export const GameReportsContainer = (props: Props) => {
    let isLoaded = false;
    const [platFormStats, setPlatFormStats] = useState<PlatformReportRes>();
    const [avgReportUnit, setAvgReportUnit] = useState('Sec.');
    useEffect(() => {
        if (!isLoaded) {
            loadPlatformStats();
        }
        isLoaded = true;
    }, []);

    const loadPlatformStats = async () => {
        const data = await ReportsService.getPlatformReports('', '');
        if (data?.total_time_spent) {
            data.total_time_spent_display = (data.total_time_spent / 3600).toFixed(2);
        }
        if (data?.avg_time_spent) {
            if (data.avg_time_spent < 60) {
                data.avg_time_spent_display = data.avg_time_spent.toFixed(2);
                setAvgReportUnit('Sec.');
            } else if (data.avg_time_spent < 3600) {
                data.avg_time_spent_display = (data.avg_time_spent / 60).toFixed(2);
                setAvgReportUnit('Min.');

            } else {
                data.avg_time_spent_display = (data.avg_time_spent / 3600).toFixed(2);
                setAvgReportUnit('Hrs.');
            }
        }
        setPlatFormStats(data);
    }

    return (
        <div className='p-3'>
            <div className="d-flex">

                <div className='position-relative rounded shaodow border p-3' style={{ height: 150, width: 250, color: '#0b7ea4' }}>
                    <h6 className='text-secondary'>Platform Active Users</h6>
                    <h1 className='position-absolute bottom-0'>{((props.totalUsers) || 1) - 1}</h1>
                </div>
                <div className='position-relative ms-3 rounded shaodow border p-3' style={{ height: 150, width: 250, color: '#0b7ea4' }}>
                    <h6 className='text-secondary'>Avg. Time Spent On Platform</h6>
                    <div className='position-absolute bottom-0 d-flex align-items-baseline'>
                        <h1>{((platFormStats?.avg_time_spent_display) || 1)}</h1>
                        <h6 className='ms-2'>{avgReportUnit}</h6>
                    </div>
                </div>
                <div className='position-relative ms-3 rounded shaodow border p-3' style={{ height: 150, width: 250, color: '#0b7ea4' }}>
                    <h6 className='text-secondary'>Total Time Spent On Platform</h6>
                    <div className='position-absolute bottom-0 d-flex align-items-baseline'>
                        <h1>{((platFormStats?.total_time_spent_display) || 1)}</h1>
                        <h6 className='ms-2'>Hrs.</h6>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                {/* <MRDataGrid onRowClick={onTableRowClick} config={tableConfig} data={gameReportData}></MRDataGrid> */}
                <Outlet></Outlet>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    totalUsers: state.PlatformStore.totalUsers
});

export default connect(mapStateToProps)(GameReportsContainer);