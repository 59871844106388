import { GameReport } from "../model/game-report";
import { GameReportRes } from "../model/game-report.res";
import { PlatformReportRes } from "../model/platform-report.res";
import { UserReportRes } from "../model/user-report.res";
import API from "../utility/axios";

export class ReportsService {
    static getGameReports(start: string, end: string, sortKey: string, isAsc: number): Promise<GameReport[]> {
        return API.get(`/gamestats?start_date=${start}&end_date=${end}&sort_key=${sortKey}&is_asc=${isAsc}`);
    }

    static getIndividualGameReports(game: string, start: string, end: string): Promise<GameReport[]> {
        return API.get(`/gamestats/${game}?start_date=${start}&end_date=${end}`);
    }

    static getChallengeReports(start: string, end: string): Promise<GameReport[]> {
        return API.get(`/challenge/reports?start_date=${start}&end_date=${end}`);
    }

    static getPlatformReports(start: string, end: string): Promise<PlatformReportRes> {
        return API.get(`/gamestats/platform?start_date=${start}&end_date=${end}`);
    }

    static getUserReports(search: string): Promise<UserReportRes[]> {
        return API.get(`/gamestats/user?search=${search}`);
    }
}