import React from 'react';
import LogoImage from './../assets/images/icons/moplay.png';
import { useNavigate } from 'react-router-dom';

interface Props {
    searchView?: any;
    searchData?:any;
}

export const Header = (props: Props) => {
    const navigation = useNavigate();

    const onClickSignout = () => {
        navigation("/")
    }

    const handleSearchInput = (e:any) =>{
        props?.searchData(e.target.value)
    }

    return (
        <div className="container-fluid position-fixed" style={{ backgroundColor: '#0a4ca0', top: 0, zIndex:10 }}>
            <div className="row">
                <div className="d-flex justify-content-between py-2" style={{ height: "60px" }}>
                    <div>
                        <img src={LogoImage} style={{ width: "40px" }} />
                    </div>
                    {props?.searchView && (
                        <div>
                            <input type="text" name="search" placeholder='Search your favourite game' className='form-control ' style={{width:"250px"}} onChange={handleSearchInput}/>
                        </div>
                    )}
                    <div className="">
                        <span className='m-1'><button className='btn btn-outline-light' style={{ fontFamily: "Montserrat", fontWeight: "bold" }} onClick={onClickSignout}> <i className="bi bi-box-arrow-right mb-1"></i> Signout</button></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
