import React from 'react'

export const Loading = () => {
    return (
        <div className="spinner-overlay">
            <div className="spinner-border position-absolute " style={{ marginTop: "20px", left: "55%" }} role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    )
}
