import { MediaUploadResponse } from "../model/media-upload.res";
import { Publisher } from "../model/publisher";
import API from "../utility/axios";

export class PublishService {
    static PublisherAdd(data: string): Promise<Publisher> {
        return API.post(`/publisher`, data);
    }

    static GetPublishers(from?: number, count?: number, search?: string): Promise<void> {
        return API.get(`/publisher?from=${from}&count=${count}&search=${search}`,);
    }

    static GetPublisherById(publisherId:string): Promise<void> {
        return API.get(`/publisher/${publisherId}`);
    }

    static UpdatePublisher(data:any): Promise<Publisher> {
        return API.put(`/publisher`,data);
    }

    static DeletePublisher(publisherId:string): Promise<void> {
        return API.delete(`/publisher/${publisherId}`);
    }
}