import React from 'react'
import { Outlet } from 'react-router-dom'
import { SideMenu } from '../components/side-menu'
import { Header } from '../components/header'

export const Dashboard = () => {
    return (
        <div>
            <Header />
            <SideMenu activeMenu={"ManagePublishers"} />
            <div style={{ marginLeft: '20%', marginTop: 60 }}>
                <Outlet />
            </div>
        </div>
    )
}
