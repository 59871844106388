import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FormBuilder, { FormBuilderData } from '../components/form-builder'
import { PublishService } from '../app/service/publisher.service';
import { GameService } from '../app/service/game.service';
import { MediaUploadReq } from '../app/model/media-upload.req';
import appConfig, { IMAGE_URL } from '../config/constant';
import { Header } from '../components/header';
import { SideMenu } from '../components/side-menu';

export const AddPublisher = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [publisherLogoPreview, setPublisherLogoPreview] = useState<any>();
    const [publisherBannerPreview, setPublisherBannerPreview] = useState<any>();
    const [logoImage, setLogoImage] = useState<any>();
    const [bannerImage, setBannerImage] = useState<any>();
    const [currentParentsData, setCurrentParentsData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [resValidationError, setResValidationError] = useState('');
    const navigation = useNavigate();
    let { id } = useParams<{ id: string }>();
    const publisherId = id as string;
    const [parentsData, setParentsData] = useState<FormBuilderData>(
        {
            errors: [],
            isValid: false,
            value: {},
        })

    useEffect(() => {
        if (publisherId) {
            loadPublisherDetailsById(publisherId);
        }
    }, [publisherId])

    const loadPublisherDetailsById = async (data: any) => {
        await PublishService.GetPublisherById(data).then((res: any) => {
            console.log("res publisher  updated:", res);
            setCurrentParentsData(res);
            setPublisherLogoPreview(appConfig.IMAGE_URL + res.icon);
            setPublisherBannerPreview(appConfig.IMAGE_URL + res.banner);
        }).catch((err) => {
            console.error(err);
            setResValidationError(`Publisher already updated`)
        })
    }

    const onChangePublisherLogoFile = (e: any) => {
        const file = e.target.files[0];
        setLogoImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPublisherLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPublisherLogoPreview(null);
        }
    }

    const handleUpdatePublisherLogoImage = () => {
        document.getElementById("icon")?.click();
        setPublisherLogoPreview(null);
        setLogoImage(null);
    }

    const removePublisherLogoImage = () => {
        setPublisherLogoPreview(null);
        setLogoImage(null);
    }

    const handleInput = (data: any) => {
        data.value = { ...currentParentsData, ...data.value };
        setCurrentParentsData((prevData: any) => ({ ...prevData, ...data.value }));
        setParentsData(data);
        setResValidationError(``);
    };

    const onChangePublisherBannerFile = (e: any) => {
        const file = e.target.files[0];
        setBannerImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPublisherBannerPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleUpdatePublisheBannerImage = () => {
        document.getElementById("banner")?.click();
        setPublisherBannerPreview(null);
        setBannerImage(null);
    }

    const removePublisherBannerImage = () => {
        setPublisherBannerPreview(null);
        setBannerImage(null);
    }

    const handleClickSubmit = async () => {
        
        setIsFormSubmitted(true);
        const data = parentsData.value ? { ...parentsData.value } : { ...currentParentsData };
        const errors = parentsData.errors;
        console.log("Errors Find:-- ", errors);
        console.log("parentsDAta is valid : ", parentsData?.isValid);
        if (parentsData.isValid) {
            console.log("pulisher create payload data : ", data);
            setLoading(true);
            let PublisherIconFileRes;
            if (logoImage) {
                const publisherIconPayload: MediaUploadReq = {
                    mediaUploadType: "publisher-icon",
                    fileName: logoImage?.name
                }
                PublisherIconFileRes = await GameService.getMediaUploadUrl(publisherIconPayload);
                await GameService.uploadMediaToUrl(PublisherIconFileRes?.url, logoImage, logoImage?.type);
            }

            let PublisherBannerFileRes: any;
            if (bannerImage) {
                const publisherBannerPayload: MediaUploadReq = {
                    mediaUploadType: "publisher-banner",
                    fileName: bannerImage?.name
                }
                PublisherBannerFileRes = await GameService.getMediaUploadUrl(publisherBannerPayload);
                await GameService.uploadMediaToUrl(PublisherBannerFileRes?.url, bannerImage, bannerImage?.type);
            }

            const publisherPayload: any = {
                _id: data?._id || "",
                name: data?.name || "",
                icon: PublisherIconFileRes?.path as string || "",
                about: data?.about || "",
                rev_share: "",
                activation_status: "",
                email: data?.email || "",
                website: data?.website || "",
                developer_name: data?.developer_name || "",
                developer_email: data?.developer_email || "",
                banner: PublisherBannerFileRes?.path as string || ""
            }
            console.log("publisher data payload : ", publisherPayload);
            if (publisherId) {
                console.log("update click payload : ", publisherPayload);
                PublishService.UpdatePublisher(publisherPayload).then((res: any) => {
                    console.log("res publisher updating after update the data:", res);
                    if (res) {
                        loadPublisherDetailsById(currentParentsData?._id || "")
                    }
                    navigation('/dashboard/manage-publisher');
                    setLoading(false);
                }).catch((err) => {
                    console.error(err);
                    setLoading(false);
                    setResValidationError(`Publisher already added`)
                })
            } else {
                PublishService.PublisherAdd(publisherPayload).then((res: any) => {
                    console.log("res publisher :", res);
                    navigation('/dashboard/manage-publisher');
                    setLoading(false);
                }).catch((err) => {
                    console.error(err);
                    setLoading(false);
                    setResValidationError(`Publisher already added`)
                })
            }
        } else {
            console.log(errors);
        }
    }

    return (
        <>
            <div className='d-flex mt-2'>                
                <div className="container-fluid my-2">
                    <div className='row'>
                        <div className='fs-4 my-2 text-start fw-bold'>{publisherId ? "Update" : "Add"} Publisher</div>
                        {loading &&
                            <>
                                <div className="spinner-overlay">
                                    <div className="spinner-border " style={{ marginTop: "250px", left: "45%" }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            </>
                        }
                        {!loading && (<>
                            <div className='col-md-12'>
                                <FormBuilder onUpdate={handleInput} showValidations={isFormSubmitted}>
                                    <form action="">
                                        <div className="row mb-4">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="name" className='form-label text-start d-flex justify-content-start'>Publsiher Name</label>
                                                <input type="text" name="name" id="name" className="form-control" defaultValue={currentParentsData?.name} placeholder="Please enter publisher name" data-validate-required="Please enter publisher name" data-validate-name="name" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className='form-label text-start d-flex justify-content-start'>Publisher Email</label>
                                                <input type="text" name="email" id="email" className="form-control" defaultValue={currentParentsData?.email} placeholder="Please enter publisher Email" data-validate-required="Please enter publisher email" data-validate-email="email" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className='form-label text-start d-flex justify-content-start'>Web site</label>
                                                <input type="text" name="website" id="website" className="form-control" placeholder="Please enter website" defaultValue={currentParentsData?.website} data-validate-required="Please enter web site" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className='form-label text-start d-flex justify-content-start'>Developer Name</label>
                                                <input type="text" name="developer_name" id="developer_name" className="form-control" placeholder="Please enter developer name" defaultValue={currentParentsData?.developer_name} data-validate-required="Please enter developer name" data-validate-name="developer name" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className='form-label text-start d-flex justify-content-start'>Developer Email</label>
                                                <input type="text" name="developer_email" id="developer_email" className="form-control" defaultValue={currentParentsData?.developer_email} placeholder="Please enter developer email" data-validate-required="Please enter developer email" data-validate-email="developer email" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className='form-label text-start d-flex justify-content-start'>About</label>
                                                <textarea className="form-control" name="about" id="about" placeholder="Please enter about the game" defaultValue={currentParentsData?.about} data-validate-required="Please enter about" rows={3} />
                                            </div>
                                            <div className='col-md-5'>
                                                <label className='my-2 d-flex justify-content-start'>Icon</label>
                                                <div className={`d-flex cursor-pointer justify-content-center my-auto align-items-center border rounded border-secondary border-dashed my-2 ${publisherLogoPreview ? "my-2" : "py-3"}`}>
                                                    <div
                                                        className='file_uploader text-center d-flex flex-column justify-content-center align-items-center'
                                                    >
                                                        <label htmlFor="icon" className='cursor-pointer' style={{ cursor: "pointer" }}>
                                                            <div id="imagePreview ">
                                                                {publisherLogoPreview ? (
                                                                    <img src={publisherLogoPreview} alt="Preview" style={{ maxWidth: '100%', height: "200px", objectFit: "contain", cursor: "pointer" }} />
                                                                ) : (
                                                                    <i className="bi bi-cloud-upload fs-1 cursor-pointer"></i>
                                                                )}
                                                            </div>
                                                            {!publisherLogoPreview && (
                                                                <div>
                                                                    <p className='mb-2 f14 text-dark cursor-pointer'>Upload a Icon with extension .jpg, .png, .svg files</p>
                                                                    <span className="btn btn-primary rounded-2 custom-file-upload">
                                                                        Upload Icon
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </label>
                                                        <input id="icon" name='icon' type="file" onChange={(e) => { onChangePublisherLogoFile(e) }} style={{ display: "none" }} />
                                                        {publisherLogoPreview && (
                                                            <div className={` my-2 poster-hover-button-position}`}>
                                                                <button className='btn btn-primary mx-2' onClick={handleUpdatePublisherLogoImage}>Update Icon</button>
                                                                <button className="btn btn-danger text-white" onClick={removePublisherLogoImage}>Remove Icon</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <label className='my-2 d-flex justify-content-start'>Banner</label>
                                                <div className={`d-flex cursor-pointer justify-content-center my-auto align-items-center border rounded border-secondary border-dashed my-2 ${publisherBannerPreview ? "my-2" : "py-3"}`}>
                                                    <div
                                                        className='file_uploader text-center d-flex flex-column justify-content-center align-items-center'
                                                    >
                                                        <label htmlFor="banner" className='cursor-pointer' style={{ cursor: "pointer" }}>
                                                            <div id="imagePreview ">
                                                                {publisherBannerPreview ? (
                                                                    <img src={publisherBannerPreview} alt="Preview" style={{ maxWidth: '100%', height: "200px", objectFit: "contain", cursor: "pointer" }} />
                                                                ) : (
                                                                    <i className="bi bi-cloud-upload fs-1 cursor-pointer"></i>
                                                                )}
                                                            </div>
                                                            {!publisherBannerPreview && (
                                                                <div>
                                                                    <p className='mb-2 f14 text-dark cursor-pointer'>Upload a banner with extension .jpg, .png, .svg files</p>
                                                                    <span className="btn btn-primary rounded-2 custom-file-upload">
                                                                        Upload banner
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </label>
                                                        <input id="banner" name='banner' type="file" onChange={(e) => { onChangePublisherBannerFile(e) }} style={{ display: "none" }} />
                                                        {publisherBannerPreview && (
                                                            <div className={` my-2 poster-hover-button-position`}>
                                                                <button className='btn btn-primary mx-2' onClick={handleUpdatePublisheBannerImage}>Update Icon</button>
                                                                <button className="btn btn-danger text-white" onClick={removePublisherBannerImage}>Remove Icon</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </FormBuilder>
                                <div className='d-flex justify-content-center'>
                                    <button className='btn btn-primary px-4 py-1' style={{ backgroundColor: '#0a4ca0' }} onClick={handleClickSubmit}>{publisherId ? "Update" : "Submit"}</button>
                                    <Link to="/dashboard/manage-publisher" className='text-decoration-none'><button className='btn btn-secondary px-4 py-1 mx-2'>Cancel</button></Link>
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
        </>
    )
}
