import React, { useEffect, useState } from 'react';

interface Props {
    totalItems: number;
    itemsPerPage: number;
    onPageChange: (pageNumber: number) => void;
    rangeStart?: number;
    rangeEnd?: number;
}

const Pagination = (props: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState<any>(props?.itemsPerPage);
    const [rangeEnd, setRangeEnd] = useState(0);
    const totalPages = Math.ceil((props?.totalItems || 0) / itemsPerPage);

    useEffect(() => {
        setCurrentPage(1); // Reset current page to 1 whenever total items change
    }, [props.totalItems]);

    useEffect(() => {
        const endVal = Math.min(props.totalItems, (currentPage - 1) * props.itemsPerPage + props.itemsPerPage)
        setRangeEnd(endVal);
    }, [props.totalItems, currentPage]);


    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        props.onPageChange(pageNumber);
    };

    return (
        <nav>
            <div className='mx-2 my-1'>
                Showing {(currentPage - 1) * props.itemsPerPage + 1} - {rangeEnd} of {props.totalItems} items
            </div>
            <ul className='pagination'>
                {/* Previous Button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button onClick={() => paginate(currentPage - 1)} className='page-link'><i className="bi bi-chevron-left fw-bold"></i></button>
                </li>
                {/* Page Buttons */}
                {Array.from({ length: totalPages }).map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)} className='page-link'>{index + 1}</button>
                    </li>
                ))}
                {/* Next Button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button onClick={() => paginate(currentPage + 1)} className='page-link'><i className="bi bi-chevron-right fw-bold"></i></button>
                </li>
            </ul>

        </nav>
    );
};

export default Pagination;

