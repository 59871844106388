import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddGame from '../pages/addGame';
import GameListView from '../pages/gameListView';
import SinUp from '../pages/signUp';
import Login from '../pages/login';
import { ManagePublishers } from '../pages/manage-publishers';
import { AddPublisher } from '../pages/addPublisher';
import { Dashboard } from '../pages/dashboard';
import GameReports from '../pages/game-reports';
import { GameReportsContainer } from '../app/pages/game-reports-container';
import { InidividualGameReports } from '../app/pages/individual-game-reports';
import { ChallengeReports } from '../app/pages/challenge-reports';
import { UserReports } from '../app/pages/user-reports';
import Blog from '../app/pages/blog';

const RootNavigation = () => {
   return (
      <Routes>
         <Route path="/" element={<Login />} />
         <Route path="/sign-up" element={<SinUp />} />
         <Route path="/login" element={<Login />} />
         <Route path='/dashboard' Component={Dashboard}>
            <Route path="game" element={<AddGame />} />
            <Route path="gameUser/:id" element={<AddGame />} />
            <Route path="manage-publisher" Component={ManagePublishers} />
            <Route path="gameList" Component={GameListView} />
            <Route path="publisher" element={<AddPublisher />} />
            <Route path="publisher/:id" element={<AddPublisher />} />
            <Route path='game-reports' Component={GameReportsContainer}>
               <Route path='' Component={GameReports} ></Route>
               <Route path=':game' Component={InidividualGameReports} ></Route>
            </Route>
            <Route path="challenge-reports" Component={ChallengeReports} />
            <Route path='user-reports' Component={UserReports} />
            <Route path='blog' Component={Blog}/>
         </Route>
      </Routes>
   )
}

export default RootNavigation;
