import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import RootNavigation from './routes';
import { Provider } from 'react-redux';
import { ReduxStore } from './app/redux/store';
import { SocketSvc } from './app/service/socket.service';

function App() {

  let isSocketInitialized = false;
  useEffect(() => {
    if (!isSocketInitialized) {
      isSocketInitialized = true;
      SocketSvc.initialize();
    }
  }, [])

  return (
    <Provider store={ReduxStore}>
      <div className="App">
        <BrowserRouter>
          < RootNavigation />
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
