import { PlatformActionType, PlatformState, PlatformStateAction } from "./types";

const InitialState: PlatformState = {
    totalUsers: 0,
    user: null
};

export const PlatformReducer = (state = InitialState, action: PlatformStateAction) => {
    console.log('action ', action);
    
    switch (action.type) {
        case PlatformActionType.USER_COUNT:
            return { ...state, totalUsers: action.data }
            break;

        case PlatformActionType.USER_LOGIN:
            return { ...state, user: action.data }
            break;
        default:
            return state;
            break;
    }
}