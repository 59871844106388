import API from "../utility/axios";

export const LOGIN = (data: any): Promise<any> => {
    return API.post(`/admin`, data);
}
export const SIGNIN = (data: any): Promise<any> => {
    return API.post(`/admin/login`, data);
}
export const Userlist = (): Promise<any> => {
    return API.get(`/admin`);
}
